
//export const baseUrl = "http://192.168.1.40:8000/v1";
//export const imgUrl = "http://192.168.1.40:8000/";

const server = "uat";
//live
//uat

//process.env.SERVER_API;
//console.log("apiiii - ",server);

export const baseUrl = process.env.REACT_APP_baseUrl;
export const imgUrl = process.env.REACT_APP_IMAGE_URL;
export const adminbaseUrl = process.env.REACT_APP_adminBaseUrl;
export const exchangebaseUrl = process.env.REACT_APP_exchangebaseUrl;
export const socketUrl = process.env.REACT_APP_SOCKET_IO_URL;


/*
export const baseUrl = (server == "live") ? "https://api.dbiexchange.com/v1/":"https://apiuat.dbiexchange.com/v1";
export const imgUrl = (server == "live") ? "https://api.dbiexchange.com":"https://apiuat.dbiexchange.com";
export const adminbaseUrl = (server == "live") ? "https://api.dbiexchange.com/":"https://apiuat.dbiexchange.com/";
export const exchangebaseUrl = (server == "live") ? "https://history.dbiexchange.com/v1":"https://historyuat.dbiexchange.com/v1";
export const socketUrl = (server == "live") ? "wss://stream.dbiexchange.com/":"https://streamuat.dbiexchange.com/";
*/
//export const baseUrl = process.env.REACT_APP_baseUrl
//(server == "live") ? "https://api.dbiexchange.com/v1/":"https://apiuat.dbiexchange.com/v1";
//export const imgUrl = process.env.REACT_APP_IMAGE_URL
//(server == "live") ? "https://api.dbiexchange.com":"https://apiuat.dbiexchange.com";
//export const adminbaseUrl = process.env.REACT_APP_baseUrl
//(server == "live") ? "https://api.dbiexchange.com/":"https://apiuat.dbiexchange.com/";
//export const exchangebaseUrl = process.env.REACT_APP_exchangebaseUrl
//(server == "live") ? "https://history.dbiexchange.com/v1":"https://historyuat.dbiexchange.com/v1";
//export const socketUrl = process.env.REACT_APP_SOCKET_IO_URL
//(server == "live") ? "wss://stream.dbiexchange.com/":"https://streamuat.dbiexchange.com/";

// export const baseUrl = "http://192.168.1.104:8000/v1";
// export const imgUrl = "http://192.168.1.104";
// export const adminbaseUrl = "http://192.168.1.104/";
// export const socketUrl = "https://streamuat.dbiexchange.com/";

// export const imgUrl = "https://api.dbiexchange.com";
// export const baseUrl = "https://api.dbiexchange.com/v1";
// export const adminbaseUrl = "https://api.dbiexchange.com/";
// export const exchangebaseUrl = "https://history.dbiexchange.com/v1";
// export const socketUrl = "wss://stream.dbiexchange.com/";

let lang = (localStorage.getItem("lang")) ? localStorage.getItem("lang") : 'en';

//console.log("lANG ",lang);

export const aheader = {
    headers: {
        devicetype: 1,
        lang: lang,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
};

//console.log("aheader ",aheader);

export const aheader_form = {
    headers: {
        devicetype: 1,
        "content-type": "multipart/form-data",
        lang: lang,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
};



