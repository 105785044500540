import React from "react";
import { useTranslation } from "react-i18next";
import { formatDecimal } from "../../utils/decimalFormatter";

function MarketDepth({
  exchange,
  binanceDepth,
  handlePriceChangeChangeBinance,
  activeSellOrder,
  activeSellOrderData,
  handlePriceChangeChange,
  topBarStatus,
  livPrice,
  topBarApiData,
  secondCoin,
  activeBuyOrder,
  activeBuyOrderData,
  marketRef,
}) {
  const { t } = useTranslation();
  const formatNumber = (number) => {
    return number
      .toLocaleString("fullwide", {
        useGrouping: false,
        maximumFractionDigits: 6,
      })
      .replace(/\.?0+$/, "");
  };
  const activeSellOrderHtml = () => {
    var collectHtml = [];
    if (exchange === "binance") {
      if (binanceDepth && binanceDepth.asks) {
        const calculateProgressBarWidth = (quantity, maxQuantity) => {
          const percentage = (quantity / maxQuantity) * 100;
          return percentage > 100 ? "100%" : `${percentage.toFixed(2)}%`;
        };
        const sortedAsks = binanceDepth.asks.sort((a, b) => b[0] - a[0]);
        let asksToShow = sortedAsks.slice(10);
        const maxQuantity = binanceDepth.asks[0][1];
        const tableRows = asksToShow
          .slice(0, 10)
          .map((activeSellOrderSingle, index) => {
            const tradePrice = parseFloat(activeSellOrderSingle[0]);
            const decimalPlaces = tradePrice < 1 ? 8 : 4;
            const formattedPrice = tradePrice.toLocaleString("en-US", {
              minimumFractionDigits: decimalPlaces,
              maximumFractionDigits: decimalPlaces,
            });
            const progressBarStyle = {
              width: calculateProgressBarWidth(
                activeSellOrderSingle[1],
                maxQuantity
              ),
            };
            return (
              <tr
                className="ng-scope tr_prograss_depth"
                key={index}
                onClick={() => handlePriceChangeChangeBinance(formattedPrice)}
                style={{ cursor: "pointer" }}
              >
                <td className="f-left ng-binding magenta crypt-down">
                  <span className="tr_prograss" style={progressBarStyle}></span>
                  {formattedPrice}
                </td>
                <td className="f-left ng-binding ">
                  {formatNumber(activeSellOrderSingle[1])}
                </td>
                <td className="f-left ng-binding text-right">
                  {activeSellOrderSingle &&
                    formatNumber(
                      activeSellOrderSingle[0] * activeSellOrderSingle[1]
                    )}
                </td>
              </tr>
            );
          });

        return <tbody>{tableRows}</tbody>;
      }
    } else {
      if (activeSellOrder) {
        collectHtml = [];
        activeSellOrderData.slice(0, 10).map((item, i) => {
          collectHtml.push(
            <tr
              className="ng-scope"
              key={i}
              onClick={() => handlePriceChangeChange(item.per_price, "sell")}
              style={{ cursor: "pointer" }}
            >
              <td className="f-left ng-binding magenta crypt-down">
                {formatDecimal(item.per_price)}
              </td>
              <td className="f-left ng-binding ">
                {formatDecimal(item.sell_spend_amount)}
              </td>
              <td className="f-left ng-binding text-right">
                {formatDecimal(item.per_price * item.sell_spend_amount)}
              </td>
            </tr>
            // </a>
          );
        });
        return <tbody>{collectHtml}</tbody>;
      } else {
        return (
          <tbody>
            <tr>
              <td colSpan={3} style={{ textAlign: "center" }}>
                {t("No Data Found")}
              </td>
            </tr>
          </tbody>
        );
      }
    }
  };

  const sideLastHtml = () => {
    if (topBarStatus !== false) {
      if (exchange === "binance") {
        const tradePrice = parseFloat(livPrice); // Get the last price from the item object
        const decimalPlaces = tradePrice < 1 ? 8 : 4; // Determine the number of decimal places based on the value of the number
        const formattedPrice = tradePrice.toLocaleString("en-US", {
          minimumFractionDigits: decimalPlaces,
          maximumFractionDigits: decimalPlaces,
        });
        return (
          <ul>
            <li>
              <strong className="ng-binding mm">{formattedPrice}</strong>
              <strong className="transMoney ng-binding ng-scope"></strong>
            </li>
          </ul>
        );
      } else {
        var topBarData = topBarApiData;
        var collectHtml = (
          <ul>
            <li>
              <strong className="ng-binding mm">
                {secondCoin === "INR" ? (
                  <span style={{ color: "#07bc8d", fontWeight: "500" }}>₹</span>
                ) : (
                  <span></span>
                )}
                 
                {(topBarData.currentPrice)?parseFloat(topBarData.currentPrice).toFixed(8):0}
              </strong>
              <strong className="transMoney ng-binding ng-scope"></strong>
            </li>
          </ul>
        );
      }

      return <ul>{collectHtml}</ul>;
    } else {
      collectHtml = (
        <ul>
          <li>
            <strong className="ng-binding">
              {secondCoin === "INR" ? (
                <span style={{ color: "#07bc8d", fontWeight: "500" }}>₹</span>
              ) : (
                <span></span>
              )}
              {0.0}
            </strong>
            <strong className="transMoney ng-binding ng-scope"></strong>
          </li>
        </ul>
      );
      return <ul>{collectHtml}</ul>;
    }
  };

  const activeBuyOrderHtml = () => {
    var collectHtml = [];
    if (exchange === "binance") {
      if (binanceDepth && binanceDepth.bids) {
        const calculateProgressBarWidth = (quantity, maxQuantity) => {
          const percentage = (quantity / maxQuantity) * 100;
          return percentage > 100 ? "100%" : `${percentage.toFixed(2)}%`;
        };

        const maxQuantity = binanceDepth.bids[0][1];
        const tableRows = binanceDepth.bids
          .slice(0, 10)
          .map((activeBuyOrderSingle, index) => {
            const tradePrice = parseFloat(activeBuyOrderSingle[0]);
            const decimalPlaces = tradePrice < 1 ? 8 : 4;
            const formattedPrice = tradePrice.toLocaleString("en-US", {
              minimumFractionDigits: decimalPlaces,
              maximumFractionDigits: decimalPlaces,
            });
            const progressBarWidth = calculateProgressBarWidth(
              activeBuyOrderSingle[1],
              maxQuantity
            );

            return (
              <tr
                className="ng-scope tr_prograss_depth"
                key={index}
                onClick={() => handlePriceChangeChangeBinance(formattedPrice)}
                style={{ cursor: "pointer" }}
              >
                <td className="f-left ng-binding green crypt-up">
                  <span
                    className="tr_prograss2"
                    style={{ width: `${progressBarWidth}` }}
                  ></span>
                  {formattedPrice}
                </td>
                <td className="f-left ng-binding ">
                  {formatNumber(activeBuyOrderSingle[1])}
                </td>
                <td className="f-left ng-binding text-right">
                  {activeBuyOrderSingle &&
                    formatNumber(
                      activeBuyOrderSingle[0] * activeBuyOrderSingle[1]
                    )}
                </td>
              </tr>
            );
          });

        return <tbody>{tableRows}</tbody>;
      }
    } else {
      if (activeBuyOrder) {
        collectHtml = [];
        activeBuyOrderData.slice(0, 10).map((item, i) => {
          
          collectHtml.push(
            <tr
              className="ng-scope"
              key={i}
              onClick={() => handlePriceChangeChange(item.per_price, "buy")}
              style={{ cursor: "pointer" }}
            >
              <td className="f-left ng-binding green crypt-up">
                {formatDecimal(item.per_price)}
              </td>
              <td className="f-left ng-binding ">
                {formatDecimal(item.buy_get_amount)}
              </td>
              <td className="f-left ng-binding text-right">
                {formatDecimal(item.per_price * item.buy_get_amount)}
              </td>
            </tr>
          );
        });
        return <tbody>{collectHtml}</tbody>;
      } else {
        return (
          <tbody>
            <tr>
              <td colSpan={3} style={{ textAlign: "center" }}>
                {t("No Data Found")}
              </td>
            </tr>
          </tbody>
        );
      }
    }
  };

  return (
    <>
      <div className="col-md-6 col-lg-3 order-md-1" ref={marketRef}> 
        <div className="ne_box h_100 ">
          <h6>{t("Market Depth")}</h6>
          <div className=" bs_border">
            <table className="table mb-0 ">
              <thead>
                <tr>
                  <th>{t("Price")}</th>
                  <th>{t("Qty.")}</th>
                  <th className="text-right">
                    {t("Total")}({secondCoin})
                  </th>
                </tr>
              </thead>
              {activeSellOrderHtml()}
            </table>
          </div>
          <h6 className="text-center t2t m-0"> {sideLastHtml()}</h6>
          <div className="bs_border">
            <table className="table mb-0 ">
              <thead>
                <tr>
                  <th>{t("Price")}</th>
                  <th>{t("Qty.")}</th>
                  <th className="text-right">
                    {t("Total")}({secondCoin})
                  </th>
                </tr>
              </thead>
              {activeBuyOrderHtml()}
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default MarketDepth;
